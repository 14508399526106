import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import JobVacancyGallery from "./JobVacancyGallery"
import JobVacancySidebar from "./JobVacancySidebar"

// const allJobs = graphql`
//   {
//     allJobs: allContentfulJobs {
//       nodes {
//         id
//         title
//         role
//         category
//         formLink
//         banner {
//           gatsbyImageData
//         }
//       }
//       categoriesList: distinct(field: category)
//     }
//   }
// `

// const allJobs = graphql`
//   {
//     allJobs: allContentfulJobs(sort: { fields: role, order: DESC }) {
//       nodes {
//         id
//         title
//         role
//         category
//         formLink
//         banner {
//           childrenImageSharp {
//             gatsbyImageData
//           }
//         }
//       }
//       categoriesList: distinct(field: category)
//     }
//   }
// `

// const getFilterSections = jobs => {
//   const filters = []
//   jobs.map(job => {
//     const index = filters.findIndex(filter => filter.role === job.role)

//     if (index === -1) {
//       filters.push({
//         title: job.title,
//         role: job.role,
//         category: job.category,
//         banner: job.banner,
//       })
//     }
//   })
//   return filters
// }

// const filterImages = (filterKey, jobs, categoryKey) => {
//   const roleFiltered = jobs.filter(job =>
//     filterKey === "all" ? job : job.role === filterKey
//   )

//   const categoryFiltered = roleFiltered.filter(job =>
//     categoryKey === "all" ? job : job.category === categoryKey
//   )

//   return categoryFiltered
// }

const JobVaccancy = props => {
  // const {
  //   allJobs: { nodes: jobs, categoriesList },
  // } = useStaticQuery(allJobs)

  // console.log("JOBS")
  // console.log(jobs)

  // const emptyQuery = ""

  // const [state, setState] = useState({
  //   list: jobs,
  //   filterKey: "Professionals",
  //   categoryKey: "all",
  //   searchResult: [],
  //   query: emptyQuery,
  // })

  // const filterCategory = categoryKey => {
  //   setState({
  //     ...state,
  //     categoryKey,
  //   })
  // }

  // const { searchResult, list, filterKey, categoryKey } = state

  // let filteredList = filterImages(filterKey, list, categoryKey)
  // let filters = getFilterSections(list)

  // const [searchState, setSearchState] = useState(false)

  // const handleInputChange = event => {
  //   const query = event.target.value

  //   const { filterKey } = state

  //   const searchResult = filteredList.filter(job => {
  //     return (
  //       job.title.toLowerCase().includes(query.toLowerCase()) &&
  //       job.role === filterKey
  //     )
  //   })

  //   setState({
  //     ...state,
  //     searchResult,
  //     query,
  //   })

  //   setSearchState(true)
  // }

  // const { query } = state

  // const hasResult = searchResult && query !== emptyQuery
  // const result = hasResult ? searchResult : filteredList

  // console.log(result)

  // const collumnCls = props.col
  //   ? props.col
  //   : "card-container col-lg-4 col-md-6 col-sm-6"

  return (
    <div class="content-block job-vacancy">
      <div class="portfolio " id="portfolio">
        <div class="site-filters m-b20 filters2">
          {/* FILTERS */}
          {/* <ul class="filters " data-toggle="buttons">
            {filters.map(filter => (
              <li
                key={filter.id}
                class={filter.role === filterKey ? "btn btn-active" : "btn"}
                onClick={() => setState({ ...state, filterKey: filter.role })}
              >
                <a class="">
                  <i class={filter.icon}></i>
                  <span>{filter.role}</span>
                </a>
              </li>
            ))}
          </ul> */}
        </div>

        {/* <div className="row">
          <div className="col-12">
            <p className="job-txt">
              <span className="job-txt-icon">
                <i class="ti-info-alt"></i>
              </span>
              If you have any problems regarding the job application, you can
              directly email to <b>info.igk@innogene.com.sg</b>
            </p>
          </div>
        </div> */}

        {/* {result > 0 && (
          <div className="row job-gallery-and-sidebar">
            <div class="col-lg-9 col-md-12">
              <JobVacancyGallery
                searchState={searchState}
                result={result}
                filteredList={filteredList}
                collumnCls={collumnCls}
              />
            </div>

            <JobVacancySidebar
              categories={categoriesList}
              filterCategory={filterCategory}
              handleInputChange={handleInputChange}
              categoryKey={categoryKey}
            />
          </div>
        )} */}
      </div>
    </div>
  )
}

export default JobVaccancy
