import React, { useState, Component } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import CareerTestimonial from "./../components/custom-components/careerTestimonial"
import ScrollToTop from "../components/scrollToTop"
import FloatingBtn from "../components/custom-components/floatingBtn"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import CareerGallerySlider from "../components/custom-components/CareerGallerySlider"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/hanif-main.css"
import "../css/custom-css/custom-career.css"

import JobVaccancy from "../components/custom-components/JobVaccancy"
import Seo from "../components/seo"

export const query = graphql`
  {
    under1: contentfulCareerImg(title: { eq: "underbanner1" }) {
      image {
        gatsbyImageData
      }
    }
    under2: contentfulCareerImg(title: { eq: "underbanner2" }) {
      image {
        gatsbyImageData
      }
    }
    under3: contentfulCareerImg(title: { eq: "underbanner3" }) {
      image {
        gatsbyImageData
      }
    }
    undervid: contentfulCareerImg(title: { eq: "underbanner-vid" }) {
      image {
        gatsbyImageData(height: 1200)
      }
    }
    panca1: contentfulCareerImg(title: { eq: "pancaS1" }) {
      image {
        gatsbyImageData
      }
    }
    panca2: contentfulCareerImg(title: { eq: "pancaS2" }) {
      image {
        gatsbyImageData
      }
    }
    panca3: contentfulCareerImg(title: { eq: "pancaS3" }) {
      image {
        gatsbyImageData
      }
    }
    panca4: contentfulCareerImg(title: { eq: "pancaS4" }) {
      image {
        gatsbyImageData
      }
    }
    panca5: contentfulCareerImg(title: { eq: "pancaS5" }) {
      image {
        gatsbyImageData
      }
    }
    perk1: contentfulCareerImg(title: { eq: "perk1" }) {
      image {
        gatsbyImageData(height: 250, width:250)
      }
    }
    perk2: contentfulCareerImg(title: { eq: "perk2" }) {
      image {
        gatsbyImageData(height: 250, width:250)
      }
    }
    perk3: contentfulCareerImg(title: { eq: "perk3" }) {
      image {
        gatsbyImageData(height: 250, width:250)
      }
    }
    perk4: contentfulCareerImg(title: { eq: "perk4" }) {
      image {
        gatsbyImageData(height: 250, width:250)
      }
    }
  }
`

const Career = () => {
  const [isOpen, setOpen] = useState(false)
  const {
    under1,
    under2,
    under3,
    undervid,
    panca1,
    panca2,
    panca3,
    panca4,
    panca5,
    act1,
    act2,
    perk1,
    perk2,
    perk3,
    perk4,
  } = useStaticQuery(query)

  return (
    <div className="skin-1 career">
      <Seo title="Career" />

      <Header careerPage />
      <FloatingBtn />
      <div class="dlab-bnr-inr bg-pt hero-section home-bnr1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-12">
              <div className="hero-content">
                <h1 className="title">
                  BEGIN YOUR CAREER AS INSAN KALBE, NOW!
                </h1>

                <div className="buttons">
                  <a href="#availJobs">See Available Positions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-full" id="choose-us">
        <div class="row m-lr0">
          <div
            class="col-xl-6 col-lg-12 p-lr0 wow fadeInRight"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div class="row spno">
              <div class="mozaic-top col-lg-6 col-md-6 col-sm-6 bg-white d-flex first-mozaic">
                <div class="dlab-services-box text-black">
                  <h3>Everyday is Learning Day</h3>
                  <p class="title m-b0">
                    Innogene Kalbiotech aims to catalyze Insan Kalbe’s growth
                    and personal development through experiences &amp;
                    knowledge.
                  </p>
                </div>
              </div>
              {/* <div class="col-lg-6 col-md-6 col-sm-6">
                <GatsbyImage
                  image={getImage(under1.image)}
                  imgStyle={{ height: "auto" }}
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <GatsbyImage
                  image={getImage(under3.image)}
                  imgStyle={{ height: "auto" }}
                />
              </div> */}
              <div class="mozaic-bot col-lg-6 col-md-6 col-sm-6 bg-white d-flex">
                <div class="dlab-services-box text-black">
                  <h3 class="title m-b15">Our Goal</h3>
                  <p class="title m-b0">
                    Innogene Kalbiotech Pte Ltd aims to be the best
                    Biopharmaceutical Company in the region which can be
                    attained through Innovation, strong brand and excellent
                    management.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mozaic-top col-xl-6 col-lg-12 p-lr0 wow fadeInRight"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div class="row spno">
              <div class="col-lg-6 col-md-6 col-sm-6 bg-white d-flex">
                <div class="dlab-services-box text-black">
                  <h3 class="title m-b15">Collaborative and Diverse Culture</h3>
                  <p class="title m-b0">
                    We may come from a different background, race, and religion
                    but we always work as a team to give positive and productive
                    contribution to Innogene Kalbiotech.
                  </p>
                </div>
              </div>
              {/* <div class="col-lg-6 col-md-6 col-sm-6">
                <GatsbyImage
                  image={getImage(under2.image)}
                  imgStyle={{ height: "auto" }}
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="video-bx radius-no h100">
                  <GatsbyImage
                    image={getImage(undervid.image)}
                    imgStyle={{ height: "auto" }}
                  />
                  <div class="video-play-icon" id="mozaic-video">
                    <Link
                      to="#"
                      onClick={e => {
                        e.preventDefault()
                        setOpen(true)
                      }}
                      class="popup-youtube video bg-primary"
                    >
                      <i class="fa fa-play"></i>
                    </Link>
                  </div>
                </div>
              </div> */}
              <div class=" mozaic-bot col-lg-6 col-md-6 col-sm-6 bg-white d-flex last-mozaic">
                <div class="dlab-services-box text-black">
                  <h3 class="title m-b15">Be The Part of Our Team</h3>
                  <p class="title m-b0">
                    If you are dedicated, conscientious, and willing to give the
                    best of you, be the part of our team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          allowFullScreen
          isOpen={isOpen}
          videoId="TKfOI8xFQNk"
          onClose={() => setOpen(false)}
        />
      )}
      <div class="section-full bg-white content-inner">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title text-center m-b50">
                <h2>Panca Sradha</h2>
              </div>
              <div class="section-content box-sort-in p-b0 button-example">
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-4 m-b30">
                    <div class="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca1.image)} />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Trust is the glue of life
                        </h5>
                        <p className="text-center">
                          With mutual trust and respect, and by upholding
                          openness and honesty, we manage the Company to deliver
                          the very best for all.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 m-b30">
                    <div class="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca2.image)} />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Mindfulness is the foundation
                        </h5>
                        <p className="text-center">
                          Mindfullness is the foundation for us to take actions
                          that are consistent with the Company’s values in order
                          to always be responsive to the needs of all
                          stakeholders.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 m-b30">
                    <div class="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca3.image)} />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Innovation is the key to our success
                        </h5>
                        <p className="text-center">
                          Starting from simplicity combined with a spirit to
                          sustain innovation, we grow to improve the quality of
                          life.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 m-b30">
                    <div class="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca4.image)} />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Strive to be the best
                        </h5>
                        <p className="text-center">
                          We provide equal opportunity to every individual to
                          develop their potentials and become a reliable human
                          being through the culture of continuous learning and
                          improvement.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 m-b30">
                    <div class="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca5.image)} />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Interconnectedness is a universal
                          <br /> way of life
                        </h5>
                        <p className="text-center">
                          As part of life, we assume our role and maintain
                          diversity and harmony by making efforts that are
                          useful to others and to the future generations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        class="section-full content-inner-2 wow fadeIn activities-communities"
        data-wow-duration="2s"
        data-wow-delay="0.6s"
      >
        <div class="content-block">
          <div class="portfolio actcoms" id="portfolio">
            <div className="portfolio_area">
              <div className="row sp15 actcom">
                <div className="card-container col-lg-6 col-md-6 col-sm-12">
                  <div class="dlab-media dlab-img-overlay1 dlab-img-effect portbox3">
                    <GatsbyImage image={getImage(act1.image)} />
                    <div class="overlay-bx">
                      <div class="portinner">
                        <div class="port-up">
                          <h3 class="port-title">See our activities</h3>
                        </div>
                        <div class="port-down">
                          <Link to="/activities-communities" class="btn-link">
                            View Detail <i class="la la-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-container col-lg-6 col-md-6 col-sm-12">
                  <div class="dlab-media dlab-img-overlay1 dlab-img-effect portbox3">
                    <GatsbyImage image={getImage(act2.image)} />
                    <div class="overlay-bx">
                      <div class="portinner">
                        <div class="port-up">
                          <h3 class="port-title">See our communities</h3>
                        </div>
                        <div class="port-down">
                          <Link to="/activities-communities" class="btn-link">
                            View Detail <i class="la la-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="section-full content-inner bg-white benefits">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title text-center m-b50">
                <h2>Perks of Working at Innogene Kalbiotech</h2>
              </div>
              <div class="section-content box-sort-in p-b0 button-example">
                <div class="row justify-content-around">
                  <div class="col-6 col-md-2 col-lg-2 m-b30">
                    <div class="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        image={getImage(perk1.image)}
                      />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Learning
                          <br />
                          Program
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-lg-2 m-b30">
                    <div class="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        image={getImage(perk2.image)}
                      />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Work-Life
                          <br /> Balance
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-lg-2 m-b30">
                    <div class="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        image={getImage(perk3.image)}
                        width={330}
                        height={330}
                      />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          Career
                          <br /> Development
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-lg-2 m-b30">
                    <div class="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        image={getImage(perk4.image)}
                      />
                      <div class="icon-content">
                        <h5 class="dlab-tilte text-capitalize">
                          A Warm <br />
                          Culture
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-full bg-gray content-inner-2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="sort-title clearfix text-center">
                <h2>What They Say About Working in Innogene?</h2>
              </div>
            </div>
          </div>

          <div class="section-content">
            <CareerTestimonial />
          </div>
        </div>
      </div>
      {/* <div
          className="section-full dlab-we-find bg-img-fix p-b20 bg-white wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="0.6s"
        >
          <div className="section-content">
            <CareerGallerySlider contentWrapperCls="sliderStyle3" hideBtn />
          </div>
        </div> */}
      <div id="availJobs"></div>
      <div style={{ marginTop: "5rem" }}>
        <CareerGallerySlider contentWrapperCls="sliderStyle3" hideBtn />
      </div>
      <div
        // id="availJobs"
        class="section-full bg-white content-inner-2 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.6s"
      >
        <div class="container">
          <div class="row p-l0 sp10">
            <div class="col-lg-12">
              <JobVaccancy />
            </div>
          </div>
        </div>
      </div>
      <Footer hideContactInfo />
      <ScrollToTop className="icon-up" />
    </div>
  )
}
export default Career
