import React from "react"

import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import "../../css/slider-animations.css"
import "../../css/homeslider.css"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// const query = graphql`
//   {
//     banner: allContentfulCareerSliderImages {
//       nodes {
//         image {
//           gatsbyImageData(quality: 100, layout: FULL_WIDTH)
//         }
//       }
//     }
//   }
// `

const query = graphql`
  {
    banner: contentfulCareerImg(title: { eq: "how-to-join" }) {
      image {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`

const CareerGallerySlider = props => {
  // const {
  //   banner: { nodes: banner },
  // } = useStaticQuery(query)
  const { banner } = useStaticQuery(query)

  return (
    <div className="career-gallery">
      {/* <Slider className="slider-wrapper home-slider2">
        {banners.map((item, index) => (
          <div key={index} className="slider-content ">
            <GatsbyImage image={getImage(item.image)} />
          </div>
        ))}

      </Slider> */}
      {/* <div className="slider-content ">
          <GatsbyImage image={getImage(banner.image)} />
        </div> */}
      <div>
        <GatsbyImage image={getImage(banner.image)} />
      </div>
    </div>
  )
}

export default CareerGallerySlider
